import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "vision",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#vision",
        "aria-label": "vision permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vision`}</h3>
    <p>{`We grow. We lead. We succeed.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/df2f574645f4934e5fbd0e4b5346778d/d61c2/01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADSElEQVQoz02S708TBxjH+ydsKkJbfOULskTpErMYXy2D9lpMNKkLUBxU3XRkYgJF5YcC8cfYDG57AUTScHeUHy2N0yq0XMuxkVkGVG2HJEVom4PSXxba3vXueu1dwd7Wzix78s2TJ988n+d5XjwCIQCVVsEiOVSqyGchAB05DYvksFgOixWwEID+L7Gi4Ms/+AIhAKm7UKXGcq7VXH195vS156p25PNvnqjaEGmjSazIN+WHysCjZ0alV0yn6o2fKMdKZOAH+GyzGWgyf3lz7qzGWn3DJrtqKQF0pVUjJfKRg5XQoUrwQAX88RdQkRQ+UjUiUuiKpLBIrsvDRZXa63csF5X3j56orr/0/Y8D5nN1raqGlp4Ba//wbN9D/Y0ffr3d09/W0dfScr/9p9mGlqHPZN+JTzaJFDpBsWxY0zH0qKmx7XzVg5udniV0sLPpXvO3qBHWP7yHjOoGO5udE72DV9UvB7qnUVfHXe2b0Z76r1oPARP5s8803Lr49RW1urGvH46sra7bntump/4wTswODC7+7nqNIG6n81lnl7v3ltsdeAyDzl9UOq32o8oxwWEpOA49fjH+AOxtHx3WTr32WlYwO7bzyPanaQ4Ze2pEUFtk0bXQ97NLrbxc16u50AC2Ku+2dx+sgAXFMthqQrMeO7U6H/atPfMmTJ7YlI+YwTbmAis2/8qy20EvzMdQJPPiNxf6l/mpyzH/dgh0FEtBgUg+oresbngxrz/OcLlXgficN+on0lGccGxuJimWf8/7gqEN/3aO53cjxPL8WuIdYX8TzcPFgA6xObLby1QEYzJ7oTgRTFBcjseTtMcf3EmQ6XQWW1/zeTw8nwuHdl3O9XSKQ5cCBVgKWhe29rksniBTTJYvRC6XY5i9NPOeTu3TNMutLrJBLMfzCZz2YeE0w83+B1vsW1QqG95lCJLFqUycoJM0S5BsKEqFoiROshSZogiSSnEExb2LpSmKnVnw5+HDUhB1BAgqsxVOMuzeDk5vhyIxnMlw+27/7lsszGT2kjRHxHGSZikmuxmMJ2IE6ihsFgJQmXJcUmsorzUcr9GX1xgkKmN5reFYjV6impSoJo/X6Asy/FtIVJOf1k2WKcf/ec+/Aa4NCVBV3hLKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "01",
            "title": "01",
            "src": "/static/df2f574645f4934e5fbd0e4b5346778d/c1b63/01.png",
            "srcSet": ["/static/df2f574645f4934e5fbd0e4b5346778d/5a46d/01.png 300w", "/static/df2f574645f4934e5fbd0e4b5346778d/0a47e/01.png 600w", "/static/df2f574645f4934e5fbd0e4b5346778d/c1b63/01.png 1200w", "/static/df2f574645f4934e5fbd0e4b5346778d/d61c2/01.png 1800w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "mission",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#mission",
        "aria-label": "mission permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mission`}</h3>
    <p>{`Pechersk School International is a diverse learning community which provides an academically challenging programme in a student-centred environment. As an IB world school, PSI is committed to developing well-rounded, global citizens by promoting personal excellence in the classroom and beyond.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/59efd2eac509cc418631815f27049381/d61c2/02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADx0lEQVQ4y0VRXWgcVRReRPChIOiDoKBvBYuIRXyvKaK+iGIaUTTV1odAFxFjbS1SJKlVMMpiTNMGY5NCmybZn+bHTXaz3aSmbX5pq6akyXZ3Z2Zndieb/Znd+d2dnTmfzDSlF8495zv3+z7OvddzheFoQdzC9ZyIKTYDf5JBiOEQTLNuOHXIyTsRTDEP+mkWM3wWUV7AdIbH2AMNecbYDK1IFfynqLRSqdJCoUSzQo5uinmaF7cokGYpwDjBUYDN0EROpBDH0zgvUJjP0gQnUIjJODwEGY48/kSSeuNz6J65St3RGC6u3sI1cRtzuS3E+SxmMhlMJBlMpu5jcmMTfbF5+O+u48KtNfjvJTCVSiAucBRmM87U5OlfWKKnnn4SLzz3DO164nG0ftyCcrWEdOo+GgAqpSw22RyEjIDF6RiOt36KUM8J/PpLF+ZW/sGPHcfR0vwOhedvYMKZcGBpiZ7d9RhOfrCP9r+8G4de2oONbh8Wz/fgxrU4woO9mOz/AeG+NgT6vsTs5CDyc5cwGBzGSDSC6aEzaD/QRP7xUYR5kTxnZ+Lkbf4QTGKTzl/247Pnd2PlxVcR2LsHh1/bi0tdP2F54H38faUJ8ZAXR78+CK/3CI51n8G3vlO4vfgX7oR8NHVzHpMMS57BaIyiJ9pgFe7Q4vDPaP/8bXx35Bham97DoU+O4qO2w/Cd/QqnfG+iv/cA2t99A8379qOr5XV4Ww/i+84B/NkTolfe6nDenzyR5DrpV7+BPv0FYbYd88tj8C7LOLmax+m7Mn5fW8G5jevoWV/C+O0Z1C90whrsgDXUidVzF/HH6Rgu++I09FsMgbUEeSKCSI1yCqgwBMD9iH9LNeQNy4FQGjUwShEPl61WQWb9EbZsJ7naqJAlT4QXSKkWoFYl0jQV9XodW5IMvliBoulQdA15qQRJkWHbNnTdgKKqqNdqaJgmFEWBXJVJ1XRE+B3DqqJCkiSSZRmmaaJaqUAqleBgQ9chlSVH5Bo6vVKxCMMwYFkWqtUqFFkh3TAeGdYbDdi2RQSCTYSGZaFh2bBs251GksrQNM3ta7oOTTdcHhE9vDk56khGII+zOQ1VrZFt2W5tWQ2qG6rj72Jhu0z5Qol23opQ0x+6Uc0wSSnJBMumqDNhmOMh6jqSxQoEWUVeN8BVKkgXt5FVNBRqNdwrVpAolN0zUdMhVisQVQ15w0BKkrGZK0CQFYQzAjzBNGuOJBkzwLDmaIpx69E0a/oZzsXDybQZZFgzwHDmcJIxRxyOo9nh+tOsGWA5Fzte/wMYsvC5tWIvUQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "02",
            "title": "02",
            "src": "/static/59efd2eac509cc418631815f27049381/c1b63/02.png",
            "srcSet": ["/static/59efd2eac509cc418631815f27049381/5a46d/02.png 300w", "/static/59efd2eac509cc418631815f27049381/0a47e/02.png 600w", "/static/59efd2eac509cc418631815f27049381/c1b63/02.png 1200w", "/static/59efd2eac509cc418631815f27049381/d61c2/02.png 1800w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      